import React from "react";
import { render } from "react-dom";
import Slide from "./components/slide";
import ImageGallery from 'react-image-gallery';
import concretoCuadrada from "../img/concreto_cuadrada.jpg";
import aceroCuadrada from "../img/acero_cuadrada.jpg";
import senalizacionCuadrada from "../img/senalizacion_cuadrada.jpg";
import otrosCuadrada from "../img/otros_cuadrada.jpg";

import concretoImage5 from "../img/productos/concreto_5.jpg";
import concretoImage5thumb from "../img/productos/concreto_5_thumb.jpg";
import concretoImage1 from "../img/productos/concreto_1.jpg";
import concretoImage1thumb from "../img/productos/concreto_1_thumb.jpg";
import concretoImage2 from "../img/productos/concreto_2.jpg";
import concretoImage2thumb from "../img/productos/concreto_2_thumb.jpg";
import concretoImage3 from "../img/productos/concreto_3.jpg";
import concretoImage3thumb from "../img/productos/concreto_3_thumb.jpg";
import concretoImage4 from "../img/productos/concreto_4.jpg";
import concretoImage4thumb from "../img/productos/concreto_4_thumb.jpg";

import aceroImage1 from "../img/productos/acero_1.jpg";
import aceroImage1thumb from "../img/productos/acero_1_thumb.jpg";
import aceroImage2 from "../img/productos/acero_2.jpg";
import aceroImage2thumb from "../img/productos/acero_2_thumb.jpg";
import aceroImage3 from "../img/productos/acero_3.jpg";
import aceroImage3thumb from "../img/productos/acero_3_thumb.jpg";
import aceroImage4 from "../img/productos/acero_4.jpg";
import aceroImage4thumb from "../img/productos/acero_4_thumb.jpg";

import senalizacionImage1 from "../img/productos/senalizacion_1.jpg";
import senalizacionImage1thumb from "../img/productos/senalizacion_1_thumb.jpg";
import senalizacionImage2 from "../img/productos/senalizacion_2.jpg";
import senalizacionImage2thumb from "../img/productos/senalizacion_2_thumb.jpg";
import senalizacionImage3 from "../img/productos/senalizacion_3.jpg";
import senalizacionImage3thumb from "../img/productos/senalizacion_3_thumb.jpg";
import senalizacionImage4 from "../img/productos/senalizacion_4.jpg";
import senalizacionImage4thumb from "../img/productos/senalizacion_4_thumb.jpg";
import senalizacionImage5 from "../img/productos/senalizacion_5.jpg";
import senalizacionImage5thumb from "../img/productos/senalizacion_5_thumb.jpg";

import otrosImage1 from "../img/productos/otros_1.jpg";
import otrosImage1thumb from "../img/productos/otros_1_thumb.jpg";
import otrosImage2 from "../img/productos/otros_2.jpg";
import otrosImage2thumb from "../img/productos/otros_2_thumb.jpg";
import otrosImage3 from "../img/productos/otros_3.jpg";
import otrosImage3thumb from "../img/productos/otros_3_thumb.jpg";
import otrosImage4 from "../img/productos/otros_4.jpg";
import otrosImage4thumb from "../img/productos/otros_4_thumb.jpg";

// const images = [
//     {
//       original: 'https://picsum.photos/id/1018/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1018/250/150/',
//     },
//     {
//       original: 'https://picsum.photos/id/1015/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1015/250/150/',
//     },
//     {
//       original: 'https://picsum.photos/id/1019/1000/600/',
//       thumbnail: 'https://picsum.photos/id/1019/250/150/',
//     },
// ];

const concretoImages = [
    {
        original: concretoImage5,
        thumbnail: concretoImage5thumb,
    },
    {
        original: concretoImage1,
        thumbnail: concretoImage1thumb,
    },
    {
        original: concretoImage2,
        thumbnail: concretoImage2thumb,
    },
    {
        original: concretoImage3,
        thumbnail: concretoImage3thumb,
    },
    {
        original: concretoImage4,
        thumbnail: concretoImage4thumb,
    },
];

const aceroImages = [
    {
        original: aceroImage1,
        thumbnail: aceroImage1thumb,
    },
    {
        original: aceroImage2,
        thumbnail: aceroImage2thumb,
    },
    {
        original: aceroImage3,
        thumbnail: aceroImage3thumb,
    },
    {
        original: aceroImage4,
        thumbnail: aceroImage4thumb,
    },
];

const senalizacionImages = [
    {
        original: senalizacionImage1,
        thumbnail: senalizacionImage1thumb,
    },
    {
        original: senalizacionImage5,
        thumbnail: senalizacionImage5thumb,
    },
    {
        original: senalizacionImage2,
        thumbnail: senalizacionImage2thumb,
    },
    {
        original: senalizacionImage3,
        thumbnail: senalizacionImage3thumb,
    },
    {
        original: senalizacionImage4,
        thumbnail: senalizacionImage4thumb,
    }
];

const otrosImages = [
    {
        original: otrosImage1,
        thumbnail: otrosImage1thumb,
    },
    {
        original: otrosImage2,
        thumbnail: otrosImage2thumb,
    },
    {
        original: otrosImage3,
        thumbnail: otrosImage3thumb,
    },
    {
        original: otrosImage4,
        thumbnail: otrosImage4thumb,
    },
];

class Productos extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeSlide: 0,
            maxSlidesIndex: 4
        }
    }

    selectSlideByNumber(index){
        this.setState({
            activeSlide: index
        });
    }

    nextSlide(){
        this.setState({
            activeSlide: this.state.activeSlide >= this.state.maxSlidesIndex ? 0 : this.state.activeSlide + 1
        });
    }

    prevSlide(){
        this.setState({
            activeSlide: this.state.activeSlide < 0 ? this.state.maxSlidesIndex : this.state.activeSlide - 1
        });
    }

    returnToHome(){
        this.setState({
            activeSlide: 0
        });
    }

    render(){
        return(
            <div className="content fix-padding-tablets">
                <div className="product-slider" style={{ transform: `translateX(-${this.state.activeSlide}00%)` }}>
                    <Slide isHome={true} slideIndex={0} activeSlide={this.state.activeSlide} prevButtonAction={this.prevSlide.bind(this)} nextButtonAction={this.nextSlide.bind(this)}>
                        <h1 title="Productos" className="feature-title wow fadeInUp" data-wow-delay="0.2s">Productos</h1>
                        <br/><br/>
                        <div className="products-wrapper">
                            <a href="#productos" onClick={()=>{
                                this.selectSlideByNumber(1)
                            }} className="product wow fadeInUp" data-wow-delay="0.2s">
                                <img className="product-img" src={concretoCuadrada} alt=""/>
                                <span className="product-title">Concreto</span>
                                <small className="text-center text-white">Click para ver más</small>
                            </a>
                            <a href="#productos" onClick={()=>{
                                this.selectSlideByNumber(2)
                            }} className="product wow fadeInUp" data-wow-delay="0.4s">
                                <img className="product-img" src={aceroCuadrada} alt=""/>
                                <span className="product-title">Estructuras Metálicas</span>
                                <small className="text-center text-white">Click para ver más</small>
                            </a>
                            <a href="#productos" onClick={()=>{
                                this.selectSlideByNumber(3)
                            }} className="product wow fadeInUp" data-wow-delay="0.6s">
                                <img className="product-img" src={senalizacionCuadrada} alt=""/>
                                <span className="product-title">Señalización</span>
                                <small className="text-center text-white">Click para ver más</small>
                            </a>
                            <a href="#productos" onClick={()=>{
                                this.selectSlideByNumber(4)
                            }} className="product wow fadeInUp" data-wow-delay="0.8s">
                                <img className="product-img" src={otrosCuadrada} alt=""/>
                                <span className="product-title">Gaviones y Geotextiles</span>
                                <small className="text-center text-white">Click para ver más</small>
                            </a>
                        </div>
                    </Slide>
                    <Slide slideIndex={1} activeSlide={this.state.activeSlide} onReturn={this.returnToHome.bind(this)} prevButtonAction={this.prevSlide.bind(this)} nextButtonAction={this.nextSlide.bind(this)}>
                        <h2 title="Productos" className="feature-title wow fadeInUp" data-wow-delay="0.2s">Concreto</h2>
                        <ImageGallery items={concretoImages} />
                        <p className="text-center slide-text">
                        <b>Tubería de Concreto</b> <br/>
                        Es un producto para aguas pluviales y servidas. Tiene un sistema
                        estructural rígido y tenemos gran variedad en existencia. La tubería
                        de alta resistencia puede ser entregada bajo pedido: ASTM C76 Clase III,
                        ASTM C76 Clase IV, ASTM C76 Clase V, Tubo Perforado y Canaleta.
                        </p>
                    </Slide>
                    <Slide slideIndex={2} activeSlide={this.state.activeSlide} onReturn={this.returnToHome.bind(this)} prevButtonAction={this.prevSlide.bind(this)} nextButtonAction={this.nextSlide.bind(this)}>
                        <h2 title="Productos" className="feature-title wow fadeInUp" data-wow-delay="0.2s">Estructuras Metálicas</h2>
                        <ImageGallery items={aceroImages} />
                        <p className="text-center slide-text">
                        <b>BÓVEDAS, FLUVIALES Y VEHICULARES</b>
                        </p>
                    </Slide>
                    <Slide slideIndex={3} activeSlide={this.state.activeSlide} onReturn={this.returnToHome.bind(this)} prevButtonAction={this.prevSlide.bind(this)} nextButtonAction={this.nextSlide.bind(this)}>
                        <h2 title="Productos" className="feature-title wow fadeInUp" data-wow-delay="0.2s">Señalización</h2>
                        <ImageGallery items={senalizacionImages} />
                        <p className="text-center slide-text">
                        Venta e instalación de defensa metálica bajo la norma AASHTO M-180, 
                        la norma de galvanizado ASTM A-123, reflectivo alta intensidad y 
                        contamos con señalización vial.
                        <br/><br/>
                        Contamos con un equipo de instalación altamente especializado,
                        <br/><br/>
                        Venta e instalación de defensa metálica bajo la norma
                        AASHTO M-180 y la norma de galvanizado ASTM
                        A-123. Contamos con un equipo de instalación
                        altamente especializado para hincar postes de
                        defensa metálica. 
                        <br/><br/>
                        Suministro y aplicación de pintura termoplástica y
                        trafico para señalización horizontal. También
                        señalizamos horizontalmente.
                        </p>
                    </Slide>
                    <Slide slideIndex={4} activeSlide={this.state.activeSlide} onReturn={this.returnToHome.bind(this)} prevButtonAction={this.prevSlide.bind(this)} nextButtonAction={this.nextSlide.bind(this)}>
                        <h2 title="Productos" className="feature-title wow fadeInUp" data-wow-delay="0.2s">Gaviones y Geotextiles</h2>
                        <ImageGallery items={otrosImages} />
                        <p className="text-center slide-text">
                        Gaviones, Colchones, Geotextiles y Mantos anti erosión de suelos. Todos con control y normas de
                        calidad internacional.
                        {/* Otros productos como Gaviones y Colchones, Geotextil, Mantos
                        para erosión de suelos y Supresor de polvo (HMI). Todos
                        con control y normas de calidad internacional. */}
                        </p>
                    </Slide>
                </div>
                <br/><br/>
                <a href="mailto:ventas@inesco-sa.com" className="btn btn-feature background-secondary wow fadeInUp my-2" data-wow-delay="0.2s">Solicitar Cotización</a>
                <br/>
            </div>
        );
    }
}

render(<Productos/>, document.getElementById("productos"));