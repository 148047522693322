import React from "react";

export default function Slide(props){
    return(
        <div className={" " + (props.activeSlide == props.slideIndex ? "product-slide active" : "product-slide")}>
            {/* {!props.isHome ? <a href="#productos" onClick={props.onReturn} className="product-slide-back-button"><i class="fas fa-arrow-left fa-sm"></i> Regresar a los productos</a> : ""} */}
            {props.children}
            {!props.isHome ? 
            <div className="slide-navigation">
                <a href="#productos" className="slide-arrow" onClick={props.prevButtonAction}><i className="fas fa-chevron-left fa-sm"></i><span className="d-none d-md-inline"> Anterior</span></a>
                <a href="#productos" onClick={props.onReturn} className="product-slide-back-button">Regresar</a>
                <a href="#productos"  className="slide-arrow" onClick={props.nextButtonAction}><span className="d-none d-md-inline">Siguiente </span><i className="fas fa-chevron-right fa-sm"></i></a>
            </div> : ""}
        </div>
    );
}